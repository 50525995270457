/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Store the last scroll position
let lastScrollPosition = 0
let isQueryChange = false

export const onInitialClientRender = () => {
  // Store initial scroll position
  lastScrollPosition = window.scrollY
}

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // Check if this is just a query parameter change
  isQueryChange =
    location.pathname === prevLocation?.pathname &&
    location.search !== prevLocation?.search
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Only handle scroll behavior for the search page
  if (location.pathname === '/search/') {
    // If we're on the search page and only query params changed, prevent scroll
    if (
      location.pathname === prevLocation?.pathname &&
      location.search !== prevLocation?.search
    ) {
      // Prevent default scroll behavior
      if (typeof window !== 'undefined') {
        window.history.scrollRestoration = 'manual'
      }
      return
    }
  }

  // For actual page navigations, scroll to top
  if (
    location.pathname !== prevLocation?.pathname &&
    !window.history.state?.key
  ) {
    window.scrollTo(0, 0)
    lastScrollPosition = 0
  }

  // Reset the query change flag
  isQueryChange = false
}

export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  // For the search page, don't scroll when only query params change
  if (routerProps?.location?.pathname === '/search/') {
    if (
      routerProps?.location?.pathname === prevRouterProps?.location?.pathname &&
      routerProps?.location?.search !== prevRouterProps?.location?.search
    ) {
      return false
    }
  }

  // For back/forward navigation between pages
  if (window.history.action === 'POP') {
    const currentPosition = getSavedScrollPosition(routerProps.location) || [
      0, 0,
    ]
    window.scrollTo({
      top: currentPosition[1],
      left: currentPosition[0],
      behavior: 'instant',
    })
    return false
  }

  // For other cases, let Gatsby handle scroll behavior
  return true
}
