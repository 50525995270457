import { useProfileData } from '../AuthProvider'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import * as React from 'react'

const Logo = () => {
  const profile = useProfileData({ optional: true })

  return !profile ? (
    <Link to='/' className={style.logo}>
      <span aria-label='ball' className={style.dot} />
      doggos
    </Link>
  ) : (
    <Link to='/dashboard' className={style.logo}>
      <span aria-label='ball' className={style.dot} />
      doggos
    </Link>
  )
}

export default Logo
